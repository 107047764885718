"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    navigate(event) {
        var _a, _b;
        if (event.currentTarget.classList.contains('active')) {
            return;
        }
        (_b = (_a = this.element.querySelector('.active')) === null || _a === void 0 ? void 0 : _a.classList) === null || _b === void 0 ? void 0 : _b.remove('active');
        event.currentTarget.classList.add('active', 'loading');
    }
}
exports.default = default_1;
