"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_assertTargetsArePresent, _default_1_setupDatePickers, _default_1_setupEventListeners, _default_1_removeEventListeners, _default_1_handleStartChange;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
/**
 * This controller provides interactivity on the create holiday modal in the client organization's users page.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
    }
    connect() {
        super.connect();
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_assertTargetsArePresent).call(this);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setupDatePickers).call(this);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setupEventListeners).call(this);
    }
    disconnect() {
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_removeEventListeners).call(this);
        super.disconnect();
    }
    toggleMode(event) {
        const target = event.currentTarget;
        if (target === null) {
            throw new Error('Target is null');
        }
        const containerId = $("input", target).val() == 0 ? "#per_location" : "#single_user";
        $(containerId).siblings().hide();
        $(containerId).show();
        if ($("input", target).val() == 0) {
            $("[name$='replacement]']").val('').trigger("change");
        }
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_assertTargetsArePresent = function _default_1_assertTargetsArePresent() {
    if (!(this.hasStartTarget && this.hasEndTarget && this.hasToggleTarget)) {
        throw new Error('Targets were not set up properly');
    }
}, _default_1_setupDatePickers = function _default_1_setupDatePickers() {
    $(this.startTarget).datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: 0,
    });
    $(this.endTarget).datepicker({
        dateFormat: 'yy-mm-dd',
        minDate: 0,
    });
}, _default_1_setupEventListeners = function _default_1_setupEventListeners() {
    $(this.startTarget).on('change', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleStartChange).bind(this));
    this.toggleTargets.forEach((toggle) => {
        toggle.addEventListener('click', this.toggleMode.bind(this));
    });
}, _default_1_removeEventListeners = function _default_1_removeEventListeners() {
    $(this.startTarget).off('change', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleStartChange).bind(this));
}, _default_1_handleStartChange = function _default_1_handleStartChange(e) {
    $(this.endTarget).datepicker('option', 'minDate', new Date(this.startTarget.value));
};
default_1.targets = ['start', 'end', 'toggle'];
