"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_handleTurboSubmitEnd;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const turbo_1 = require("@hotwired/turbo");
/**
 * This controller reloads a turbo-frame when a form submission is finished.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
    }
    connect() {
        this.element.addEventListener('turbo:submit-end', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleTurboSubmitEnd).bind(this));
    }
    disconnect() {
        this.element.removeEventListener('turbo:submit-end', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleTurboSubmitEnd).bind(this));
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_handleTurboSubmitEnd = function _default_1_handleTurboSubmitEnd(event) {
    if (!this.hasTurboFrameValue) {
        console.debug('No turbo-frame outlet found. Not triggering a reload.', event);
        return;
    }
    const frames = document.querySelectorAll(this.turboFrameValue);
    console.debug(frames);
    // Only close the modal if the form submission was successful and redirected to a new page.
    // it is likely that the form's redirect is targeted onto the whole page, or to a different turbo-frame.
    if (!event.detail.success || !event.detail.fetchResponse.redirected) {
        console.debug('Not triggering a reload as the form submission was not finished.', event);
        return;
    }
    for (const frame of frames) {
        const reloadEvent = new Event('turbo-frame:reload');
        frame.addEventListener('turbo-frame:load', (event) => {
            if (!reloadEvent.defaultPrevented) {
                if (frame instanceof turbo_1.FrameElement) {
                    (async function () { await frame.reload(); })();
                }
            }
        });
        frame.dispatchEvent(reloadEvent);
    }
};
default_1.values = {
    turboFrame: String
};
