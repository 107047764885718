"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_handleTurboSubmitEnd;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
/**
 * This controller closes the modal (an outlet, see {@link ModalController}) when a turbo form submission is successful and redirects to a new page.
 * If the form is a multistep form, this should still be a viable controller as the last step will redirect
 * if successful. Intermediate steps will not redirect, but instead render a new part.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
    }
    connect() {
        this.element.addEventListener('turbo:submit-end', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleTurboSubmitEnd).bind(this));
    }
    disconnect() {
        this.element.removeEventListener('turbo:submit-end', __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleTurboSubmitEnd).bind(this));
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_handleTurboSubmitEnd = function _default_1_handleTurboSubmitEnd(event) {
    // Only close the modal if the form submission was successful and redirected to a new page.
    // it is likely that the form's redirect is targeted onto the whole page, or to a different turbo-frame.
    if (!event.detail.success || !event.detail.fetchResponse.redirected) {
        console.debug('Not closing modal as form submission was not successful or did not redirect to a new page.', event);
        return;
    }
    console.debug('Closing modal as form submission was successful and redirected to a new page.', event);
    this.modalOutlet.close();
};
default_1.outlets = ['modal'];
