"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor(context) {
        super(context);
        this.toggle = this.toggle.bind(this);
    }
    connect() {
        super.connect();
        if (!this.hasToggleTarget) {
            return;
        }
        this.toggleTarget.addEventListener('change', this.toggle);
        this.toggle();
    }
    disconnect() {
        super.disconnect();
        if (!this.toggleTarget) {
            return;
        }
        this.toggleTarget.removeEventListener('change', this.toggle);
    }
    toggle() {
        console.log('Toggling!', this.toggleTarget);
        if (!this.toggleTarget) {
            return;
        }
        if (this.toggleTarget.checked) {
            this.disableTargets();
        }
        else {
            this.enableTargets();
        }
    }
    enableTargets() {
        console.log(this.inputsTargets);
        this.inputsTargets.forEach((target) => {
            console.log('Toggling inputs', target.querySelectorAll('input'));
            target.querySelectorAll('input').forEach((input) => input.disabled = false);
        });
    }
    disableTargets() {
        this.inputsTargets.forEach((target) => {
            target.querySelectorAll('input').forEach((input) => input.disabled = true);
        });
    }
}
exports.default = default_1;
default_1.targets = ["toggle", "inputs"];
