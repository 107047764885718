"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SampleOrderButtonsComponent_instances, _SampleOrderButtonsComponent_createAction;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SampleOrderButtonsComponent = void 0;
class SampleOrderButtonsComponent {
    constructor() {
        _SampleOrderButtonsComponent_instances.add(this);
    }
    init(props) {
        this.eGui = document.createElement('div');
        this.eGui.style.display = 'flex';
        this.eGui.style.gap = '0.375rem';
        for (const actionName in props.data.actions) {
            const action = props.data.actions[actionName];
            this.eGui.appendChild(__classPrivateFieldGet(this, _SampleOrderButtonsComponent_instances, "m", _SampleOrderButtonsComponent_createAction).call(this, action));
        }
        $('.js-remoteContent', this.eGui).remoteContent();
        $(".js-ajaxFormButtonEmail", this.eGui).ajaxForm();
        $(".js-ajaxFormButton", this.eGui).ajaxForm();
    }
    getGui() {
        return this.eGui;
    }
    refresh() {
        return false;
    }
    destroy() {
    }
}
exports.SampleOrderButtonsComponent = SampleOrderButtonsComponent;
_SampleOrderButtonsComponent_instances = new WeakSet(), _SampleOrderButtonsComponent_createAction = function _SampleOrderButtonsComponent_createAction(action) {
    const button = document.createElement('a');
    button.href = action.href;
    button.title = action.tooltip;
    for (const attributeName in action.attributes) {
        button.dataset[attributeName] = action.attributes[attributeName];
    }
    button.innerHTML = `<span class="fa ${action.icon}"></span>`;
    button.classList.add(...action.classes);
    return button;
};
