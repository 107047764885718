"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    toggleAssetSelected(event) {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }
        const id = event.params.assetId;
        if (event.target.checked) {
            this.selectedAssetsValue = [...this.selectedAssetsValue, id];
        }
        else {
            this.selectedAssetsValue = this.selectedAssetsValue.filter((entry) => entry !== id);
        }
    }
    assetSelectorTargetConnected(element) {
        this.updateCheckboxOfAsset(element);
    }
    moveButtonTargetConnected(element) {
        this.updateMoveButtonUI(this.assetMovingEnabledValue, element);
    }
    controlMeasureButtonTargetConnected(element) {
        this.updateControlMeasureButtonUI(this.assetMovingEnabledValue, element);
    }
    updateCheckboxOfAsset(element) {
        const assetIdString = element.dataset.assetId;
        if (!assetIdString) {
            throw new Error("Expected asset-id data attribute to be set on bulk asset target");
        }
        element.checked = this.selectedAssetsValue.find(assetId => assetId === parseInt(assetIdString)) !== undefined;
    }
    selectedAssetsValueChanged(newSelectedAssets) {
        $(this.bottomBarTarget).toggle(newSelectedAssets.length > 0);
        this.amountOfAssetsSelectedTarget.innerText = newSelectedAssets.length.toString();
        this.assetSelectorTargets.forEach(this.updateCheckboxOfAsset.bind(this));
    }
    /**
     * There are several bulk actions, they are configured through event params and processed through this generic method
     */
    bulkAction(event) {
        const url = event.params.url;
        if (!url) {
            throw new Error("Unable to get URL for bulk action");
        }
        const componentsField = this.formTarget.querySelector('input[name="component_bulk_form[components]"]');
        if (!componentsField) {
            throw new Error("Unable to find components field within bulk form");
        }
        componentsField.value = JSON.stringify(this.selectedAssetsValue);
        this.formTarget.setAttribute('action', url);
        this.formTarget.submit();
    }
    cancelBulkSelection() {
        this.selectedAssetsValue = [];
        this.assetMovingEnabledValue = false;
    }
    toggleAssetMoving(event) {
        this.assetMovingEnabledValue = !this.assetMovingEnabledValue;
        const target = event.currentTarget;
        if (!(target instanceof HTMLButtonElement)) {
            throw new Error("Expected target for asset moving toggle to be button");
        }
        target.classList.toggle('active', this.assetMovingEnabledValue);
    }
    assetMovingEnabledValueChanged(isEnabled) {
        this.updateButtonUIs(isEnabled);
    }
    updateButtonUIs(isMovingEnabled) {
        this.moveButtonTargets.forEach(this.updateMoveButtonUI.bind(this, isMovingEnabled));
        this.controlMeasureButtonTargets.forEach(this.updateControlMeasureButtonUI.bind(this, isMovingEnabled));
    }
    updateMoveButtonUI(isMovingEnabled, element) {
        element.style.display = isMovingEnabled ? 'inline-block' : 'none';
    }
    updateControlMeasureButtonUI(isMovingEnabled, element) {
        element.style.display = isMovingEnabled ? 'none' : 'inline-block';
    }
    get visibleSelectedRows() {
        return this.assetSelectorTargets
            .filter(selector => {
            const assetId = parseInt(selector.dataset.assetId || '0');
            return this.selectedAssetsValue.find(entry => entry === assetId) !== undefined;
        })
            .map(selector => selector.closest('tr'))
            .filter(row => row !== null);
    }
    get allVisibleTableRows() {
        return this.assetSelectorTargets
            .map(selector => selector.closest('tr'))
            .filter(row => row !== null);
    }
    async updateOrderOfAssets(event) {
        const assetIdToAppendTo = parseInt(event.params.assetId);
        const response = await fetch(this.updateAssetOrderEndpointValue, {
            credentials: 'same-origin',
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                asset_id_to_append_to: assetIdToAppendTo,
                asset_ids_to_append: this.selectedAssetsValue,
            })
        });
        const data = await response.json();
        if (!response.ok || data.status === 'warning') {
            console.error('Unable to update position of assets on server', data);
            return;
        }
        this.dispatch('reload-table', {
            prefix: '',
            detail: {
                onReloadComplete: (table) => {
                    // We received the position of the asset we appended to from the server
                    // This position is in the list of all assets in the location
                    // @ts-ignore
                    table.scroller.toPosition(data.positionOfAppendedToAsset - 1, true);
                }
            }
        });
    }
}
exports.default = default_1;
default_1.targets = ['assetSelector', 'bottomBar', 'amountOfAssetsSelected', 'form', 'moveButton', 'controlMeasureButton'];
default_1.values = {
    selectedAssets: {
        type: Array,
        default: []
    },
    assetMovingEnabled: {
        type: Boolean,
        default: false
    },
    updateAssetOrderEndpoint: String
};
