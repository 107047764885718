"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
/**
 * Content in a turbo-frame is often not cleared before loading new content. In some cases, this is desirable, but in
 * others, it is not. This controller will clear the content of a turbo-frame before loading new content.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.originalContent = '';
    }
    connect() {
        if (this.element.tagName.toLowerCase() !== 'turbo-frame') {
            console.error('turbo-frame-clearer-controller must be used on a <turbo-frame> element');
            return;
        }
        this.originalContent = this.element.innerHTML;
        // @todo probably need to check the event.target to be turbo-frame
        //       or use turbo:before-visit instead
        //       because right now it interferes with other controllers' functioning
        this.element.addEventListener('turbo:before-fetch-request', (event) => this.clear(event));
    }
    disconnect() {
        this.element.removeEventListener('turbo:before-fetch-request', this.clear.bind(this));
    }
    /**
     * Clear the inner content of the turbo-frame.
     */
    clear(e) {
        if (e.target !== this.element) {
            return;
        }
        this.element.innerHTML = this.originalContent;
    }
}
exports.default = default_1;
