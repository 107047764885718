"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        $(this.element)
            .on('ajaxForm.load.success', this.onFormLoaded.bind(this))
            .on('ajaxForm.success', this.onComponentCreated.bind(this))
            .on('ajaxForm.fail', this.onFormFailed.bind(this));
    }
    disconnect() {
        $(this.element)
            .off('ajaxForm.load.success')
            .off('ajaxForm.success')
            .off('ajaxForm.fail');
    }
    onFormLoaded(e, data) {
        $('#infoMessage').hide();
        console.warn("Form loaded", e, data);
    }
    onComponentCreated(e, data) {
        console.warn("Created component", e, data);
        if (data === null || data === void 0 ? void 0 : data.auto_reopen) {
            this.modal.one('hidden.bs.modal', () => {
                this.element.click();
            });
        }
        this.modal.modal('hide');
        this.modal.find('#questionnaire_component_qrCode').removeClass('is-invalid');
        this.dispatch('reload-table', { prefix: '', });
    }
    onFormFailed(e, data) {
        let qrcheck = this.modal.find(".assetRegisterSubmit").attr("data-qr");
        // @ts-ignore
        if (1 == qrcheck) {
            this.modal.find('#questionnaire_component_qrCode').addClass('is-invalid');
            $('.assetRegisterSubmit').removeAttr('data-qr');
        }
    }
    get modal() {
        const modalSelector = this.element.dataset['target'];
        if (!modalSelector) {
            throw new Error('Unable to find modal selector for button');
        }
        return $(modalSelector);
    }
}
exports.default = default_1;
