"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        var _a, _b;
        let fileCount = parseInt(document.getElementById('organization_email_template_attachments').dataset.prototypeCount || '0');
        (_a = document.querySelector('.js-add-file')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', (event) => {
            event.preventDefault();
            const attachmentsContainer = document.getElementById('organization_email_template_attachments');
            if (!attachmentsContainer)
                return;
            let prototypeTemplate = attachmentsContainer.getAttribute('data-prototype');
            if (!prototypeTemplate)
                return;
            prototypeTemplate = prototypeTemplate
                .replace(/__name__label__/g, '')
                .replace(/__name__/g, fileCount.toString());
            const newWidget = document.createElement('div');
            newWidget.innerHTML = prototypeTemplate;
            const newWidgetElement = newWidget.firstElementChild;
            if (!newWidgetElement)
                return;
            attachmentsContainer.appendChild(newWidgetElement);
            const customFileInput = newWidgetElement.querySelector('.custom-file-input');
            if (customFileInput) {
                customFileInput.addEventListener('change', function () {
                    const label = customFileInput.nextElementSibling;
                    if (label) {
                        label.classList.add('selected');
                        label.textContent = customFileInput.value.split('\\').pop() || '';
                    }
                });
            }
            fileCount++;
            return false;
        });
        (_b = document.getElementById('organization_email_template_attachments')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', (event) => {
            const target = event.target;
            if (target.matches('.fa-trash')) {
                const row = target.closest('.row');
                if (row) {
                    row.remove();
                }
            }
        });
    }
}
exports.default = default_1;
