"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const ux_live_component_1 = require("@symfony/ux-live-component");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.component = undefined;
    }
    async initialize() {
        this.component = await (0, ux_live_component_1.getComponent)(this.element);
        this.component.on('render:finished', (component) => {
            this.loadData();
        });
        if (this.hasListingTarget) {
            this.listingTarget.addEventListener('wheel', this.onScroll.bind(this));
        }
    }
    connect() {
        this.loadData();
    }
    loadData() {
        this.element.classList.add('is-loading');
        $(() => {
            this.markReady();
        });
    }
    buildChart(json) {
        if (!this.element) {
            return;
        }
        const locationCount = Object.keys(json.taskData).length;
        let locationCounter = this.element.querySelector('.location-counter');
        if (locationCounter) {
            locationCounter.innerHTML = locationCount.toString();
        }
    }
    markReady() {
        this.element.querySelectorAll('.chart').forEach((el) => {
            el.classList.add('is-ready');
        });
        this.element.classList.remove('is-loading');
    }
    onScroll(event) {
        if (event.deltaX === 0 && event.target instanceof Element) {
            if (event.target.nodeName !== 'TABLE'
                && event.target.nodeName !== 'TR'
                && event.target.nodeName !== 'TD'
                && event.target.nodeName !== 'SPAN') {
                event.preventDefault();
                // @ts-ignore
                this.listingTarget.scrollBy(event.deltaY, 0);
            }
        }
    }
    showError() {
        this.element.classList.add('has-error');
    }
    filterLocations(event) {
        const searchParam = event.target.value.toLowerCase();
        this.element.querySelectorAll('.js-list-item').forEach((listItem) => {
            var _a;
            const label = (_a = listItem.querySelector('.js-location-label')) === null || _a === void 0 ? void 0 : _a.innerHTML.replace('\n', '').toLowerCase();
            listItem.classList.add('is-hidden');
            if (label) {
                if (label.indexOf(searchParam) > -1) {
                    listItem.classList.remove('is-hidden');
                }
                else {
                    listItem.classList.add('is-hidden');
                }
            }
        });
    }
}
exports.default = default_1;
default_1.targets = ['listing'];
