"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        if (this.element.classList.contains('disabled')) {
            this.element.classList.remove('disabled');
        }
    }
    open() {
        this.modalOutlet.open();
    }
}
exports.default = default_1;
default_1.outlets = ['modal'];
