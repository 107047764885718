"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        this.handleSearch();
        this.setupDataTable();
    }
    setupDataTable() {
        const table = $(this.componentTableTarget).DataTable({
            paging: false,
            dom: "ltipr",
            order: [],
            columnDefs: [
                {
                    "targets": this.unsortableIndexes,
                    "orderable": false
                },
                {
                    "type": "natural-ci",
                    "targets": this.sortNaturalIndexes
                }
            ]
        });
        $(this.searchComponentsTarget).on('change keyup blur', (e) => {
            e.preventDefault();
            const search = e.target.value;
            this.handleSearch(search);
            table.search(search).draw();
        });
    }
    handleSearch(searchTerm = '') {
        const hasSearchTerm = searchTerm.length > 0;
        $('.js-multi-move').toggleClass('disabled', hasSearchTerm);
    }
    toggleSelectAll(event) {
        const isChecked = event.target.checked;
        if (isChecked) {
            $(this.selectCheckBoxTargets).not(':checked').prop('checked', isChecked);
            this.riskAssessmentMultiSelectBottomBarOutlet.amountTarget.innerText = $(this.selectCheckBoxTargets).filter(':checked').length.toString();
        }
        else {
            $(this.selectCheckBoxTargets).filter(':checked').prop('checked', isChecked);
            this.riskAssessmentMultiSelectBottomBarOutlet.amountTarget.innerText = "0";
        }
        this.riskAssessmentMultiSelectBottomBarOutlet.showMultiselectBottomBar();
    }
    toggleSelect(event) {
        this.riskAssessmentMultiSelectBottomBarOutlet.amountTarget.innerText = $(this.selectCheckBoxTargets).filter(':checked').length.toString();
        this.riskAssessmentMultiSelectBottomBarOutlet.showMultiselectBottomBar();
    }
    handleForm() {
        const form = this.bulkFormTarget;
        const assets = [];
        $(this.selectCheckBoxTargets).filter(':checked').each((index, checkbox) => {
            assets.push($(checkbox).closest('tr').attr('data-id'));
        });
        const assetsInput = $('#restore_deleted_asset_assets');
        assetsInput.val(JSON.stringify(assets));
        form.submit();
    }
    get unsortableIndexes() {
        return $("#componentTable > thead > tr > th[data-unsortable]").map(function () {
            return $(this).index();
        }).toArray();
    }
    get sortNaturalIndexes() {
        return $("#componentTable > thead > tr > th[data-sort-natural]").map(function () {
            return $(this).index();
        }).toArray();
    }
}
exports.default = default_1;
default_1.targets = ["componentTable", "bulkForm", "searchComponents", "selectCheckBox"];
default_1.outlets = ["risk-assessment--multi-select-bottom-bar"];
