"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    filterAssetType() {
        const target = this.filterInputTarget;
        const assetType = target.value;
        const allAssets = document.querySelectorAll(`[data-asset-type]`);
        allAssets.forEach((asset) => {
            if (asset.getAttribute('data-asset-type') === assetType) {
                asset.classList.remove('d-none');
            }
            else {
                asset.classList.add('d-none');
                if (assetType === 'all') {
                    asset.classList.remove('d-none');
                }
            }
        });
    }
}
exports.default = default_1;
default_1.targets = ["filterInput"];
