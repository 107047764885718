"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    idsToFilterValueChanged(newValue, oldValue) {
        let value = JSON.parse(newValue);
        if (Array.isArray(value) && value.length === 0) {
            value = null;
        }
        else if (value === '' || value === null) {
            value = [];
        }
        this.dispatch('set-custom-option', {
            prefix: '',
            detail: {
                optionName: 'assetIdsToFilter',
                optionValue: value,
            }
        });
    }
}
exports.default = default_1;
default_1.values = {
    idsToFilter: {
        type: String,
        default: 'null'
    }
};
