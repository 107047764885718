"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
let count = 0;
/**
 * This class is mixing up being responsible for a single entry in the list
 * and managing the whole list. It should be split in two controllers, each with their own tasks.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        this.selectedItems = [];
    }
    toggle() {
        const checkbox = this.checkboxTarget;
        checkbox.checked = !checkbox.checked;
        const itemStatus = checkbox.checked ? 'increment' : 'decrement';
        this.handleItemsSelected(itemStatus);
        if (checkbox.checked) {
            this.toggleSelectElement(this.element);
        }
        else {
            this.toggleDeSelectElement(this.element);
        }
        checkbox.dispatchEvent(new Event('change', { bubbles: true }));
    }
    toggleSelectElement(item) {
        let selectText = item.getElementsByClassName('select-text')[0];
        item.classList.add('selected-asset');
        item.classList.remove('border');
        //TODO: Deselect should translate here
        selectText.textContent = 'Deselect';
    }
    toggleDeSelectElement(item) {
        let selectText = item.getElementsByClassName('select-text')[0];
        item.classList.remove('selected-asset');
        item.classList.add('border');
        selectText.textContent = 'Select';
    }
    handleItemsSelected(status) {
        if (status == 'increment') {
            count++;
        }
        else {
            count--;
        }
        this.changeAssetCounterText(count);
    }
    changeAssetCounterText(count) {
        const counterElement = document.getElementById('items-selected');
        if (counterElement) {
            counterElement.textContent = count + " ";
        }
    }
    selectAll() {
        this.allItems = document.querySelectorAll('.asset-item:not(.d-none)');
        Array.from(this.allItems).forEach((item) => {
            item.classList.add('selected-asset');
            const checkbox = item.querySelector("input[type='checkbox']");
            if (checkbox) {
                checkbox.checked = true;
            }
            this.toggleSelectElement(item);
            checkbox.dispatchEvent(new Event('change', { bubbles: true }));
        });
        this.selectedItems = [...new Set([...Array.from(this.allItems), ...this.selectedItems])];
        count = this.selectedItems.length;
        this.changeAssetCounterText(count);
    }
    deSelectAll() {
        this.allItems = document.querySelectorAll('.asset-item:not(.d-none)');
        let selectedItems = Array.from(document.querySelectorAll('.selected-asset'));
        Array.from(this.allItems).forEach((item) => {
            item.classList.remove('selected-asset');
            const checkbox = item.querySelector("input[type='checkbox']");
            if (checkbox) {
                checkbox.checked = false;
            }
            this.toggleDeSelectElement(item);
            checkbox.dispatchEvent(new Event('change', { bubbles: true }));
            selectedItems = selectedItems.filter(selectedItem => selectedItem != item);
        });
        count = selectedItems.length;
        this.changeAssetCounterText(count);
    }
}
exports.default = default_1;
default_1.targets = ['checkbox'];
