"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        $(this.element)
            .on('change', () => {
            this.element.requestSubmit();
        });
        window.addEventListener('popstate', this.onPopState.bind(this));
        const locationParts = document.location.href.split('/');
        const lastLocationPart = locationParts[locationParts.length - 1];
        let initialWeekOffset = 0;
        if (lastLocationPart !== 'calendar') {
            initialWeekOffset = parseInt(lastLocationPart, 10);
        }
        const initialState = {
            weekOffset: initialWeekOffset
        };
        history.replaceState(initialState, "");
    }
    onPopState(event) {
        if (event.state) {
            this.setWeekOffset(event.state.weekOffset);
        }
    }
    disconnect() {
        window.removeEventListener('popstate', this.onPopState.bind(this));
    }
    updateWeek(delta) {
        const val = parseFloat(this.weekOffsetInputTarget.value);
        let newVal = 0;
        if (delta !== 0) {
            newVal = val + delta;
        }
        history.pushState({ weekOffset: newVal }, "", `/planning/calendar/${newVal}`);
        this.setWeekOffset(newVal);
    }
    setWeekOffset(weekOffset) {
        this.weekOffsetInputTarget.value = weekOffset.toString();
        this.element.requestSubmit();
    }
    selectAllMaintenanceTypes() {
        this.toggleMaintenanceTypes(true);
    }
    selectNoMaintenanceTypes() {
        this.toggleMaintenanceTypes(false);
    }
    toggleMaintenanceTypes(state) {
        this.element.querySelectorAll('input[type="checkbox"]').forEach((el) => {
            el.checked = state;
        });
        this.element.requestSubmit();
    }
}
exports.default = default_1;
default_1.targets = ['weekOffsetInput'];
