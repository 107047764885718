"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        this.initWidgetContainerScrollShadow();
        if (!this.hasShadowLeftTarget) {
            this.setupLeftTarget();
        }
        if (!this.hasShadowRightTarget) {
            this.setupRightTarget();
        }
    }
    widgetContainerTargetConnected() {
        this.initWidgetContainerScrollShadow();
    }
    shadowLeftTargetDisconnected() {
        this.setupLeftTarget();
    }
    shadowRightTargetDisconnected() {
        this.setupRightTarget();
    }
    setupLeftTarget() {
        var _a;
        if (!this.hasWidgetContainerTarget) {
            return;
        }
        let shadowLeft = document.createElement('div');
        shadowLeft.classList.add('overflow-shadow', 'left');
        shadowLeft.setAttribute('data-compliance-dashboard--compliance-dashboard-shadow-target', 'shadowLeft');
        (_a = this.widgetContainerTarget.parentElement) === null || _a === void 0 ? void 0 : _a.append(shadowLeft);
    }
    setupRightTarget() {
        var _a;
        if (!this.hasWidgetContainerTarget) {
            return;
        }
        let shadowRight = document.createElement('div');
        shadowRight.classList.add('overflow-shadow', 'right');
        shadowRight.setAttribute('data-compliance-dashboard--compliance-dashboard-shadow-target', 'shadowRight');
        (_a = this.widgetContainerTarget.parentElement) === null || _a === void 0 ? void 0 : _a.append(shadowRight);
    }
    initWidgetContainerScrollShadow() {
        window.addEventListener('resize', this.checkWidgetContainerScroll.bind(this));
        this.widgetContainerTarget.addEventListener('wheel', this.debounce(this.checkWidgetContainerScroll.bind(this), 100));
        if (!this.hasShadowLeftTarget) {
            this.setupLeftTarget();
        }
        if (!this.hasShadowRightTarget) {
            this.setupRightTarget();
        }
        this.checkWidgetContainerScroll();
    }
    checkWidgetContainerScroll() {
        const scrollLeft = this.widgetContainerTarget.scrollLeft;
        const scrollMax = this.widgetContainerTarget.scrollWidth;
        // @ts-ignore
        const parentWidth = this.widgetContainerTarget.offsetParent.clientWidth;
        const scrollRight = scrollMax - scrollLeft - parentWidth;
        const shadowLeft = this.shadowLeftTarget;
        const shadowRight = this.shadowRightTarget;
        if (scrollLeft > 0) {
            shadowLeft === null || shadowLeft === void 0 ? void 0 : shadowLeft.classList.add('is-visible');
        }
        else {
            shadowLeft === null || shadowLeft === void 0 ? void 0 : shadowLeft.classList.remove('is-visible');
        }
        if (scrollRight > 0) {
            shadowRight === null || shadowRight === void 0 ? void 0 : shadowRight.classList.add('is-visible');
        }
        else {
            shadowRight === null || shadowRight === void 0 ? void 0 : shadowRight.classList.remove('is-visible');
        }
    }
    disconnect() {
    }
    debounce(callback, wait) {
        let timeoutId;
        return (...args) => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    }
}
exports.default = default_1;
default_1.targets = ['widgetContainer', 'shadowLeft', 'shadowRight'];
