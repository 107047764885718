"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _instances, _shouldHide;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
/**
 * The dashboard has an up-sell message to encourage customers to a more advanced plan for their locations.
 * This message can be hidden by the user, and we will remember that choice.
 */
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _instances.add(this);
    }
    connect() {
        if (!__classPrivateFieldGet(this, _instances, "m", _shouldHide).call(this)) {
            this.show();
        }
    }
    hide() {
        localStorage.setItem('hide-dashboard-upsell-message', 'true');
        this.element.classList.add('d-none');
    }
    show() {
        this.element.classList.remove('d-none');
    }
}
exports.default = default_1;
_instances = new WeakSet(), _shouldHide = function _shouldHide() {
    return localStorage.getItem('hide-dashboard-upsell-message') === 'true';
};
