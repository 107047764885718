"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        super.connect();
    }
    selectOption(event) {
        const fields = JSON.parse(event.target.options[event.target.selectedIndex].dataset['fields']);
        const allFields = document.querySelectorAll('#general_data_step input[type=text]');
        const getAllFieldsNameExcludeFields = Array.from(allFields).filter(field => {
            const fieldName = field.name;
            return fieldName && !Object.keys(fields).some(key => {
                const keyRegex = new RegExp(key.replace(':', '\\:')); // Escape ':' in key for regex
                return keyRegex.test(fieldName);
            });
        }).map(field => field.name);
        const keysToSkip = ['client_organization_id', 'name', 'organization_id', 'id'];
        Object.keys(fields).forEach((key) => {
            if (keysToSkip.includes(key)) {
                return;
            }
            const fieldElement = document.querySelector('input[name*="' + key + '"]');
            if (fieldElement && fields[key] !== null) {
                fieldElement.value = fields[key];
            }
        });
        getAllFieldsNameExcludeFields.forEach((fieldName) => {
            const fieldElement = document.querySelector('input[name="' + fieldName + '"]');
            if (fieldElement) {
                fieldElement.value = '';
            }
        });
    }
}
exports.default = default_1;
