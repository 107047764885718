"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        $(this.element).on('ajaxForm.success', this.onFormSubmitted.bind(this))
            .on('ajaxForm.fail', this.onFormFailed.bind(this));
    }
    onFormSubmitted(e, data) {
        this.modal.modal('hide');
        this.modal.find('#questionnaire_component_qrCode').removeClass('is-invalid');
        const editButtonId = this.element.id;
        this.dispatch('reload-table', {
            prefix: '',
            detail: {
                onReloadComplete: (table) => {
                    $('#' + editButtonId)
                        .closest('tr')
                        .effect('highlight', { color: '#dff0d8' }, 5000);
                }
            }
        });
    }
    onFormFailed(e, data) {
        this.modal.find('#questionnaire_component_qrCode').addClass('is-invalid');
    }
    get modal() {
        const modalSelector = this.element.dataset['target'];
        if (!modalSelector) {
            throw new Error('Unable to find modal selector for button');
        }
        return $(modalSelector);
    }
}
exports.default = default_1;
