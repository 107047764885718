"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        this.checkVisibility();
        const categorySelect = this.categoryTarget.querySelector("select");
        $(categorySelect).on('select2:select', function () {
            let event = new Event('change', { bubbles: true });
            this.dispatchEvent(event);
        });
    }
    changeCategory(event) {
        this.checkVisibility();
    }
    checkVisibility() {
        const categorySelect = this.categoryTarget.querySelector("select");
        if (!categorySelect) {
            console.warn("No <select> element found inside category target.");
            return;
        }
        const option = categorySelect.options[categorySelect.selectedIndex];
        const isCategoryIncludedInReport = option.dataset.inReport;
        const checkbox = this.inReportTarget.querySelector("input[type='checkbox']");
        if (!checkbox) {
            return;
        }
        if (isCategoryIncludedInReport === 'true') {
            this.inReportTarget.style.display = "";
        }
        else {
            this.inReportTarget.style.display = "none";
            checkbox.checked = false;
        }
    }
}
exports.default = default_1;
default_1.targets = ['category', 'inReport'];
