"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
    }
    changeWeek(event) {
        this.activityPlannerPlannerFilterOutlet.updateWeek(event.params.delta);
    }
}
exports.default = default_1;
default_1.outlets = ['activity-planner--planner-filter'];
