"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_calculateAndApplyOffset;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
    }
    toggle(event) {
        event.preventDefault();
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_calculateAndApplyOffset).call(this);
        this.element.classList.toggle('active');
        $(this.submenuContainerTarget).slideToggle(250);
        this.element.blur();
    }
    get bottomBar() {
        const bottomBar = this.element.closest('.multi-select-bottom-bar');
        if (!(bottomBar instanceof HTMLDivElement)) {
            throw new Error('Unable to get container for bottom bar submenu button');
        }
        return bottomBar;
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_calculateAndApplyOffset = function _default_1_calculateAndApplyOffset() {
    const computedStyle = getComputedStyle(this.bottomBar);
    const bottomBarHeight = parseInt(computedStyle.height);
    const bottomBarBorderTopWidth = parseInt(computedStyle.borderTopWidth);
    const bottomBarTopPadding = parseInt(computedStyle.paddingTop);
    // Calculate the offset for the submenu, so it nicely overlaps a little
    this.submenuContainerTarget.style.bottom = (bottomBarHeight - bottomBarTopPadding - bottomBarBorderTopWidth) + 'px';
};
default_1.targets = ['submenuContainer'];
