"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        $(this.element).on('ajaxForm.success', this.onFormSubmitted.bind(this));
    }
    onFormSubmitted(e, { amount }) {
        if (amount > 0) {
            this.element.classList.remove('btn-success');
            this.element.classList.add('btn-measurement-existing');
        }
        else {
            this.element.classList.remove('btn-measurement-existing');
            this.element.classList.add('btn-success');
        }
        this.modal.modal('hide');
        this.tableRow.effect('highlight', { color: '#dff0d8' }, 5000);
    }
    get modal() {
        const modalSelector = this.element.dataset['target'];
        if (!modalSelector) {
            throw new Error('Unable to find modal selector for button');
        }
        return $(modalSelector);
    }
    get tableRow() {
        const row = this.element.closest('tr');
        if (!row) {
            throw new Error('Unable to find table row for button');
        }
        return $(row);
    }
}
exports.default = default_1;
