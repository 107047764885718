"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    close() {
        this.modalOutlet.close();
    }
}
exports.default = default_1;
default_1.outlets = ['modal'];
