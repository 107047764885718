import {startStimulusApp} from '@symfony/stimulus-bridge';
import * as Turbo from "@hotwired/turbo";
import register_shared_controllers from "../shared-controllers/register_shared_controllers";

Turbo.session.drive = false;

// Ensure we only load our app once, even if a turbo navigation causes a <script> to be loaded again
if (window.user_app === undefined) {
    // Registers Stimulus controllers from controllers.json and in the controllers/ directory
    const app = startStimulusApp(require.context(
        '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
        true,
        /\.(j|t)sx?$/
    ));

    register_shared_controllers(app);

    window.user_app = app;
}


