"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    showMultiselectBottomBar() {
        const checkedInputsLength = $(this.riskAssessmentRestoreDeletedAssetsOutlet.selectCheckBoxTargets).filter(':checked').length;
        checkedInputsLength > 0 ? $(this.multiSelectBottomBarTarget).show() : $(this.multiSelectBottomBarTarget).hide();
    }
    handleForm() {
        const form = this.riskAssessmentRestoreDeletedAssetsOutlet.bulkFormTarget;
        const assets = [];
        $(this.riskAssessmentRestoreDeletedAssetsOutlet.selectCheckBoxTargets).filter(':checked').each((index, checkbox) => {
            assets.push($(checkbox).closest('tr').attr('data-id'));
        });
        const assetsInput = $('#restore_deleted_asset_assets');
        assetsInput.val(JSON.stringify(assets));
        form.submit();
    }
}
exports.default = default_1;
default_1.outlets = ["risk-assessment--restore-deleted-assets"];
default_1.targets = ["multiSelectBottomBar", "amount"];
