"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
const ag_grid_community_1 = require("ag-grid-community");
const locale_1 = require("@ag-grid-community/locale");
const translator_1 = require("../../../../../translator");
const sampleOrderButtonsComponent_1 = require("./sampleOrderButtonsComponent");
require('./style.scss');
// Register all Community features
ag_grid_community_1.ModuleRegistry.registerModules([ag_grid_community_1.AllCommunityModule]);
function getLocale(locale) {
    if (locale === 'nl' || locale.indexOf('nl') >= 0) {
        return locale_1.AG_GRID_LOCALE_NL;
    }
    if (locale === 'de' || locale.indexOf('de') >= 0) {
        return locale_1.AG_GRID_LOCALE_DE;
    }
    if (locale === 'es' || locale.indexOf('es') >= 0) {
        return locale_1.AG_GRID_LOCALE_ES;
    }
    if (locale === 'en' || locale.indexOf('en') >= 0) {
        return locale_1.AG_GRID_LOCALE_EN;
    }
    return locale_1.AG_GRID_LOCALE_EN;
}
const myTheme = ag_grid_community_1.themeQuartz.withParams({
    fontSize: '13px',
});
class default_1 extends stimulus_1.Controller {
    constructor(context) {
        super(context);
        this.dateTimeFormat = null;
        this.dateFormat = null;
        this.gridApi = undefined;
        this.onDelete = this.onDelete.bind(this);
    }
    onDelete(event) {
        var _a, _b;
        const detail = event.detail;
        if ("data" in detail && "id" in detail.data) {
            const id = detail.data.id;
            const node = (_a = this.gridApi) === null || _a === void 0 ? void 0 : _a.getRowNode(`so_${id.toString()}`);
            (_b = this.gridApi) === null || _b === void 0 ? void 0 : _b.applyTransaction({
                remove: [node === null || node === void 0 ? void 0 : node.data],
            });
        }
    }
    connect() {
        super.connect();
        document.addEventListener('sample-order-deleted', this.onDelete);
        const options = {
            weekday: undefined,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: undefined,
            minute: undefined,
            second: undefined,
            timeZoneName: undefined
        };
        this.dateFormat = new Intl.DateTimeFormat(document.documentElement.lang, options);
        this.dateTimeFormat = new Intl.DateTimeFormat(document.documentElement.lang, {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'shortGeneric'
        });
        const gridOptions = {
            theme: myTheme,
            localeText: getLocale(document.documentElement.lang),
            getRowId: params => { var _a; return `so_${(_a = params.data) === null || _a === void 0 ? void 0 : _a.id.toString()}`; },
            getRowClass: params => {
                if ("assessment_status" in params.data && params.data.assessment_status !== null) {
                    return params.data.assessment_status;
                }
                return undefined;
            },
            autoSizeStrategy: {
                type: 'fitCellContents',
                skipHeader: true,
            },
            columnTypes: {
                center: {
                    cellClass: "justify-content-center"
                }
            },
            dataTypeDefinitions: {
                object: {
                    baseDataType: "object",
                    extendsDataType: "object",
                    valueParser: (params) => ({ name: params.newValue }),
                    valueFormatter: (params) => params.value == null ? "" : params.value.name,
                },
                dateTime: {
                    baseDataType: "date",
                    extendsDataType: "date",
                    valueParser: params => {
                        return null;
                    },
                    valueFormatter: (params) => {
                        var _a, _b;
                        let date = undefined;
                        if (typeof params.value === 'string') {
                            date = new Date(Date.parse(params.value));
                        }
                        else if (params.value instanceof Date) {
                            date = params.value;
                        }
                        return (_b = (_a = this.dateTimeFormat) === null || _a === void 0 ? void 0 : _a.format(date)) !== null && _b !== void 0 ? _b : 'No formatter specified';
                    }
                },
                date: {
                    baseDataType: "date",
                    extendsDataType: "date",
                    valueParser: params => {
                        return null;
                    },
                    valueFormatter: (params) => {
                        var _a, _b;
                        let date = undefined;
                        if (typeof params.value === 'string') {
                            date = new Date(Date.parse(params.value));
                        }
                        else if (params.value instanceof Date) {
                            date = params.value;
                        }
                        return (_b = (_a = this.dateFormat) === null || _a === void 0 ? void 0 : _a.format(date)) !== null && _b !== void 0 ? _b : 'No formatter specified';
                    }
                }
            },
            tooltipShowDelay: 350,
            tooltipHideDelay: 2000,
            tooltipMouseTrack: true,
            enableCellTextSelection: true,
            ensureDomOrder: true,
            defaultColDef: {
                cellStyle: (params) => ({
                    display: 'flex',
                    alignItems: 'center',
                }),
            },
            columnDefs: [{
                    field: "id",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_ID, {}, 'frontend'),
                    cellRenderer: (params) => {
                        if (!("external_reference" in params.data) || params.data.external_reference === undefined || params.data.external_reference === null) {
                            return params.value;
                        }
                        return `${params.value} <span style="text-decoration: underline">${(0, translator_1.trans)(translator_1.LAB_INTEGRATION_VIEW_EXTERNAL_REFERENCE_REFERENCE, { 'reference': params.data.external_reference }, 'frontend')} <i class="fa fa-question-circle" /></span>`;
                    },
                    tooltipValueGetter: params => {
                        if (params.data.external_reference === null) {
                            return null;
                        }
                        return (0, translator_1.trans)(translator_1.LAB_INTEGRATION_VIEW_EXTERNAL_REFERENCE_TOOLTIP, {}, 'frontend');
                    },
                    floatingFilter: true,
                }, {
                    field: "lab",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_LAB, {}, 'frontend'),
                    filter: true,
                    floatingFilter: true,
                }, {
                    field: "status",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_STATUS, {}, 'frontend'),
                    filter: true,
                }, {
                    field: "client_organization_reference",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_CLIENT, {}, 'frontend'),
                    valueFormatter: (params) => params.value.name,
                    filter: true,
                    cellRenderer: (params) => {
                        return `<a href="${params.value.href}">${params.value.name}</a>`;
                    }
                }, {
                    field: "location_reference",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_LOCATION, {}, 'frontend'),
                    valueFormatter: (params) => params.value.name,
                    cellRenderer: (params) => {
                        return `<a href="${params.value.href}">${params.value.name}</a>`;
                    },
                }, {
                    field: "identifier",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_REFERENCE, {}, 'frontend'),
                    filter: true,
                    floatingFilter: true,
                }, {
                    field: "number_of_samples",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_SAMPLES, {}, 'frontend'),
                    filter: true,
                }, {
                    field: "used_packages",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_PACKAGES, {}, 'frontend'),
                    filter: true,
                    autoHeight: true,
                    valueFormatter: (params) => params.value.map((pack) => pack.name).join(', '),
                    cellRenderer: (params) => {
                        if (params.value.length === 1) {
                            return params.value[0].name;
                        }
                        let list = '<ul class="my-2" style="padding-inline-start: 1rem">';
                        for (const pack of params.value) {
                            list += `<li style="line-height: 1.4rem;">${pack.name}</li>`;
                        }
                        return list + '</ul>';
                    }
                }, {
                    field: "created_at",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_CREATION, {}, 'frontend'),
                    filter: true,
                    cellDataType: "date",
                    type: "center",
                }, {
                    field: "planned_at",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_PLANNED, {}, 'frontend'),
                    filter: true,
                    cellDataType: "date",
                    type: "center",
                }, {
                    field: "archived",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_ARCHIVED, {}, 'frontend'),
                    filter: true,
                    cellDataType: "dateTime",
                    type: "center",
                    hide: !this.showArchivedValue.valueOf(),
                }, {
                    field: "task_group_reference",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_LINK, {}, 'frontend'),
                    filter: true,
                    cellRenderer: (params) => {
                        if (params.value === null) {
                            return (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDER_NO_TASKGROUP, {}, 'frontend');
                        }
                        if (!params.value.viewable) {
                            return (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDER_TASK_GROUP_NO_RIGHTS, {}, 'frontend');
                        }
                        return `<a href="${params.value.href}" target="_blank">${(0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDER_VIEW_TASKGROUP, {}, 'frontend')} <i class="fa fa-external-link"></i></a>`;
                    }
                }, {
                    field: "actions",
                    headerName: (0, translator_1.trans)(translator_1.LAB_INTEGRATION_SAMPLE_ORDERS_ACTIONS, {}, 'frontend'),
                    pinned: 'right',
                    cellRenderer: sampleOrderButtonsComponent_1.SampleOrderButtonsComponent,
                    type: "rightAligned",
                    suppressAutoSize: true,
                    resizable: false,
                    sortable: false,
                }],
        };
        this.containerTarget.innerHTML = '';
        this.gridApi = (0, ag_grid_community_1.createGrid)(this.containerTarget, gridOptions);
        fetch(this.srcValue.toString())
            .then(res => res.json())
            .then((rows) => {
            var _a;
            (_a = this.gridApi) === null || _a === void 0 ? void 0 : _a.setGridOption('rowData', rows);
        });
        ;
    }
    disconnect() {
        super.disconnect();
        document.removeEventListener('sample-order-deleted', this.onDelete);
    }
}
exports.default = default_1;
default_1.values = {
    src: String,
    showArchived: Boolean,
};
default_1.targets = [
    "container",
    "loader",
];
