"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        var _a;
        this.subjectInput = document.querySelector('input[name="manual_email_on_finished_phase[subject]"]');
        this.contentInput = document.querySelector('textarea[name="manual_email_on_finished_phase[content]"]');
        const selectElement = this.element;
        if (selectElement.classList.contains('select2')) {
            $(selectElement).on('select2:select', (event) => {
                this.updateInputs(event);
            });
        }
        (_a = this.contentInput) === null || _a === void 0 ? void 0 : _a.addEventListener('input', console.log);
    }
    updateInputs(event) {
        const selectElement = event.target;
        const selectedOption = selectElement.options[selectElement.selectedIndex];
        const subject = selectedOption.getAttribute('data-subject');
        const content = selectedOption.getAttribute('data-content');
        if (this.subjectInput) {
            this.subjectInput.value = subject || '';
            this.subjectInput.dispatchEvent(new Event('input', { bubbles: true }));
        }
        if (this.contentInput) {
            this.contentInput.value = content || '';
            this.contentInput.dispatchEvent(new Event('input', { bubbles: true }));
        }
    }
}
exports.default = default_1;
default_1.targets = ['subject', 'content'];
