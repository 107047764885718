"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_setupEventListeners, _default_1_removeEventListeners, _default_1_assertTargetsExist;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        this.currentFilter = "";
    }
    connect() {
        super.connect();
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_assertTargetsExist).call(this);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_setupEventListeners).call(this);
        this.currentFilter = this.searchInputTarget.value;
        this.applyFilter();
    }
    disconnect() {
        super.disconnect();
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_removeEventListeners).call(this);
    }
    applyFilter() {
        const filter = this.currentFilter.toLowerCase();
        this.itemTargets.forEach((item) => {
            var _a, _b;
            const itemText = (_b = (_a = item.textContent) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : "";
            if (itemText.trim().includes(filter)) {
                item.classList.add("d-flex");
                item.classList.remove("d-none");
            }
            else {
                item.classList.add("d-none");
                item.classList.remove("d-flex");
            }
        });
    }
    updateFilter() {
        if (this.currentFilter === this.searchInputTarget.value) {
            return;
        }
        this.currentFilter = this.searchInputTarget.value;
        this.applyFilter();
    }
}
exports.default = default_1;
_default_1_instances = new WeakSet(), _default_1_setupEventListeners = function _default_1_setupEventListeners() {
    this.searchInputTarget.addEventListener("input", this.updateFilter.bind(this));
}, _default_1_removeEventListeners = function _default_1_removeEventListeners() {
    this.searchInputTarget.removeEventListener("input", this.updateFilter.bind(this));
}, _default_1_assertTargetsExist = function _default_1_assertTargetsExist() {
    if (!this.hasSearchInputTarget) {
        throw new Error(`Missing searchInputTarget`);
    }
};
default_1.targets = ["item", "searchInput"];
