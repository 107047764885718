"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _default_1_instances, _default_1_requestInFlight, _default_1_isControlMeasureActive, _default_1_updateButtonStatus, _default_1_sendRequest, _default_1_handleResponseData, _default_1_showConfirmDialog;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        _default_1_requestInFlight.set(this, false);
        _default_1_isControlMeasureActive.set(this, false);
    }
    initialize() {
        __classPrivateFieldSet(this, _default_1_isControlMeasureActive, this.controlMeasureActiveValue, "f");
    }
    async toggle() {
        if (__classPrivateFieldGet(this, _default_1_requestInFlight, "f")) {
            return;
        }
        __classPrivateFieldSet(this, _default_1_isControlMeasureActive, !__classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f"), "f");
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateButtonStatus).call(this);
        await __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_sendRequest).call(this, __classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f"), false);
    }
}
exports.default = default_1;
_default_1_requestInFlight = new WeakMap(), _default_1_isControlMeasureActive = new WeakMap(), _default_1_instances = new WeakSet(), _default_1_updateButtonStatus = function _default_1_updateButtonStatus() {
    if (__classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f")) {
        this.element.classList.add('active');
    }
    else {
        this.element.classList.remove('active');
    }
    if (__classPrivateFieldGet(this, _default_1_requestInFlight, "f")) {
        this.element.classList.add('disabled');
    }
    else {
        this.element.classList.remove('disabled');
    }
    // Sync back to HTML
    this.controlMeasureActiveValue = __classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f");
    // Blur the element so no visual changes on it remain until something else is selected
    if (this.element instanceof HTMLElement) {
        this.element.blur();
    }
}, _default_1_sendRequest = 
/**
 *
 * @param controlMeasureActive
 * @param userHasConfirmed Sometimes we need extra confirmation to toggle it, we can indicate here if it was confirmed
 * @private
 */
async function _default_1_sendRequest(controlMeasureActive, userHasConfirmed) {
    if (__classPrivateFieldGet(this, _default_1_requestInFlight, "f")) {
        return;
    }
    try {
        __classPrivateFieldSet(this, _default_1_requestInFlight, true, "f");
        this.element.classList.add('disabled');
        let url = new URL(this.endpointValue);
        url.searchParams.append('shortcut', this.controlMeasureTypeValue);
        url.searchParams.append('value', controlMeasureActive ? '1' : '0');
        if (userHasConfirmed) {
            url.searchParams.append('confirmed', true.toString());
        }
        const response = await fetch(url, {
            credentials: 'same-origin',
            headers: {
                accept: 'application/json'
            }
        });
        if (!response.ok) {
            throw response;
        }
        const data = await response.json();
        await __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_handleResponseData).call(this, data);
    }
    catch (throwable) {
        console.error("Error while toggling control measure", throwable);
        __classPrivateFieldSet(this, _default_1_isControlMeasureActive, !controlMeasureActive, "f");
    }
    finally {
        __classPrivateFieldSet(this, _default_1_requestInFlight, false, "f");
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateButtonStatus).call(this);
    }
}, _default_1_handleResponseData = async function _default_1_handleResponseData(data) {
    if (data.success) {
        __classPrivateFieldSet(this, _default_1_isControlMeasureActive, data.newState, "f");
        this.element.classList.remove('disabled');
        return;
    }
    if (data.confirmation_required && data.confirmation_required) {
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_showConfirmDialog).call(this, data.confirmation_title, data.confirmation_message, data.confirmation_cancel, data.confirmation_confirm);
    }
}, _default_1_showConfirmDialog = function _default_1_showConfirmDialog(title, message, cancel, confirm) {
    // @ts-ignore
    $.confirm({
        title: title,
        content: message,
        escapeKey: 'cancel',
        buttons: {
            cancel: {
                text: cancel,
                btnClass: 'btn-primary',
                action: () => {
                    __classPrivateFieldSet(this, _default_1_isControlMeasureActive, !__classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f"), "f");
                    this.element.classList.remove('disabled');
                    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_updateButtonStatus).call(this);
                }
            },
            confirm: {
                text: confirm,
                btnClass: 'btn-danger active',
                keys: ['enter'],
                action: () => {
                    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_sendRequest).call(this, __classPrivateFieldGet(this, _default_1_isControlMeasureActive, "f"), true);
                }
            }
        }
    });
};
default_1.values = {
    endpoint: String,
    controlMeasureType: String,
    controlMeasureActive: Boolean,
};
