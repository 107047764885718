"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    connect() {
        $(this.element)
            .on('ajaxForm.load.success', this.onFormLoad.bind(this))
            .on('ajaxForm.success', this.onFormSubmitted.bind(this))
            .on('ajaxForm.load.fail', this.onFormLoadFailed.bind(this));
    }
    disconnect() {
        $(this.element)
            .off('ajaxForm.load.success')
            .off('ajaxForm.success')
            .off('ajaxForm.load.fail');
    }
    onFormLoad() {
        if (this.modal.has('#duplicateComponentModalSubmit')) {
            this.modal.find('#duplicateComponentModalSubmit').removeAttr('disabled');
        }
        $('#infoMessageId').show();
    }
    onFormSubmitted(e, { auto_reopen }) {
        if (this.modal.has('#duplicateComponentModalSubmit')) {
            this.modal.find('#duplicateComponentModalSubmit').removeAttr('disabled');
        }
        if (auto_reopen) {
            this.modal.one('hidden.bs.modal', () => {
                this.element.click();
            });
        }
        this.modal.modal('hide');
        this.dispatch('reload-table', {
            prefix: '',
            detail: {
                onReloadComplete: (table) => {
                    const lastRow = table.page.info().recordsTotal - 1;
                    // @ts-ignore
                    table.scroller.toPosition(lastRow);
                }
            }
        });
    }
    onFormLoadFailed(e, data) {
        const response = data.responseJSON;
        const statusType = response.status;
        (toastr[statusType])(response.message);
        this.modal.find('#duplicateComponentModalContainer').text(response.message);
        this.modal.find('#duplicateComponentModalSubmit').attr('disabled', 'disabled');
        this.modal.modal('hide');
    }
    get modal() {
        const modalSelector = this.element.dataset['target'];
        if (!modalSelector) {
            throw new Error('Unable to find modal selector for button');
        }
        return $(modalSelector);
    }
}
exports.default = default_1;
