"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _default_1_instances, _default_1_requestInFlight, _default_1_onFirstConfirm, _default_1_onSecondConfirm, _default_1_tableRow_get, _default_1_removeRow, _default_1_enabled, _default_1_processRequestError;
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor() {
        super(...arguments);
        _default_1_instances.add(this);
        _default_1_requestInFlight.set(this, false);
    }
    async delete() {
        if (__classPrivateFieldGet(this, _default_1_requestInFlight, "f")) {
            return;
        }
        // @ts-ignore
        $.confirm({
            title: this.titleValue,
            content: this.messageValue,
            escapeKey: 'cancel',
            buttons: {
                cancel: {
                    text: this.cancelValue,
                    btnClass: 'btn-primary',
                    action: () => __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_enabled).call(this)
                },
                confirm: {
                    text: this.confirmValue,
                    btnClass: 'btn-danger active',
                    keys: ['enter'],
                    action: () => __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_onFirstConfirm).call(this)
                }
            }
        });
    }
    doubleConfirm(title, message) {
        const self = $(this.element);
        // @ts-ignore
        $.confirm({
            title: title,
            content: message,
            buttons: {
                cancel: {
                    text: this.cancelValue,
                    btnClass: 'btn-primary',
                    action: () => {
                        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_enabled).call(this);
                        __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_tableRow_get).animate({ opacity: 1 }, 200);
                    }
                },
                confirm: {
                    text: this.confirmValue,
                    btnClass: 'btn-danger active',
                    keys: ['enter'],
                    action: () => __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_onSecondConfirm).call(this)
                }
            }
        });
    }
}
exports.default = default_1;
_default_1_requestInFlight = new WeakMap(), _default_1_instances = new WeakSet(), _default_1_onFirstConfirm = async function _default_1_onFirstConfirm() {
    __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_tableRow_get)
        .animate({ opacity: 0.25 }, 500);
    try {
        __classPrivateFieldSet(this, _default_1_requestInFlight, true, "f");
        const response = await fetch(this.endpointValue, {
            credentials: 'same-origin',
            headers: {
                accept: 'application/json'
            }
        });
        const data = await response.json();
        if (!response.ok) {
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_processRequestError).call(this);
        }
        if (data.status === 'pending') {
            this.doubleConfirm(data.confirmation_title, data.confirmation_message);
        }
        else {
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_removeRow).call(this);
        }
    }
    catch (error) {
        console.error(error);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_processRequestError).call(this);
    }
    finally {
        __classPrivateFieldSet(this, _default_1_requestInFlight, false, "f");
    }
}, _default_1_onSecondConfirm = async function _default_1_onSecondConfirm() {
    const url = new URL(this.endpointValue);
    url.searchParams.append('confirmed', true.toString());
    try {
        const response = await fetch(url, {
            credentials: 'same-origin',
            headers: {
                accept: 'application/json'
            }
        });
        const data = await response.json();
        if (!response.ok || data.status !== 'success') {
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_processRequestError).call(this);
        }
        else {
            __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_removeRow).call(this);
        }
    }
    catch (error) {
        console.error(error);
        __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_processRequestError).call(this);
    }
}, _default_1_tableRow_get = function _default_1_tableRow_get() {
    return $(this.element).closest('tr');
}, _default_1_removeRow = function _default_1_removeRow() {
    __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_tableRow_get)
        .animate({ opacity: 0 }, 1000, () => __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_tableRow_get).remove());
}, _default_1_enabled = function _default_1_enabled() {
    this.element.removeAttribute('disabled');
}, _default_1_processRequestError = function _default_1_processRequestError() {
    __classPrivateFieldGet(this, _default_1_instances, "m", _default_1_enabled).call(this);
    __classPrivateFieldGet(this, _default_1_instances, "a", _default_1_tableRow_get)
        .animate({ opacity: 1 }, 200)
        .effect('highlight', { color: '#f2dedf' }, 5000);
};
default_1.values = {
    endpoint: String,
    title: String,
    message: String,
    cancel: String,
    confirm: String,
};
