"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stimulus_1 = require("@hotwired/stimulus");
class default_1 extends stimulus_1.Controller {
    constructor(context) {
        super(context);
        this.suggestion = null;
        this.suggestionOpened = false;
        this.clickListener = this.clickListener.bind(this);
        this.escapeListener = this.escapeListener.bind(this);
    }
    connect() {
        super.connect();
        document.addEventListener('click', this.clickListener);
        document.addEventListener('keyup', this.escapeListener);
    }
    disconnect() {
        super.disconnect();
        document.removeEventListener('click', this.clickListener);
        document.removeEventListener('keyup', this.escapeListener);
    }
    escapeListener(event) {
        if (event.key === 'Escape' && this.suggestionOpened) {
            this.closeSuggestion();
        }
    }
    clickListener(event) {
        setTimeout(() => {
            const target = event.target;
            if (!this.suggestionOpened || this.openTarget.contains(target)) {
                return;
            }
            if (!this.popoverTarget.contains(target)) {
                this.closeSuggestion();
            }
        }, 100);
    }
    suggest() {
        this.openSuggestion();
        this.applyTarget.classList.add('disabled');
        this.applyTarget.disabled = true;
        let form = this.element.closest('form');
        if (form === null) {
            console.warn('Form was not found');
            return;
        }
        const formData = new FormData(form);
        formData.set('step', this.stepValue.toString());
        if (window.testEditor !== undefined) {
            console.info('The test editor is found on the page');
            formData.set('json', JSON.stringify(window.testEditor.get()));
        }
        fetch(this.endpointValue.toString(), {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then((data) => {
            $(this.contentTarget).stop().slideDown();
            $(this.loaderTarget).stop().slideUp();
            this.contentTarget.innerHTML = data.content;
            this.suggestion = data.suggestion;
            this.applyTarget.classList.remove('disabled');
            this.applyTarget.disabled = false;
        });
    }
    openSuggestion() {
        $(this.popoverTarget).stop().slideDown();
        $(this.loaderTarget).stop().slideDown();
        $(this.contentTarget).stop().slideUp();
        this.suggestionOpened = true;
    }
    closeSuggestion() {
        $(this.contentTarget).stop().slideDown();
        $(this.popoverTarget).stop().slideUp();
        $(this.loaderTarget).stop().slideUp();
        this.suggestionOpened = false;
    }
    apply() {
        if (this.suggestion !== null) {
            $(this.inputTarget).find('input').first().val(this.suggestion);
        }
        this.closeSuggestion();
    }
}
exports.default = default_1;
default_1.targets = ["input", "content", "popover", "loader", "apply", "open"];
default_1.values = {
    endpoint: String,
    step: String
};
